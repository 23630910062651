//Declarate functions
var screenWidth = $(window).width();
var owl = $('.slider');
function toggleController($object){
	var data = $object.data();
	if(data.object){data.object = $(data.object)}else{data.object = $object;}
	if(!data.class) data.class = 'active';
	data.object.toggleClass(data.class);
}

function itIsMobile(){
	if(screenWidth > 767){
		return false
	}else{
		return true;
	}
}

function selectStyler(){
	if(!itIsMobile()){
		$('select').styler();
	}else{
		$('select').styler('destroy');
	}
}

$(function (){
	//Catch events
	$('.toggle-controller').on('click', function(){
		toggleController($(this));
	});

	owl.owlCarousel({
		items: 1,
		nav: true,
		loop: true,
	});

	selectStyler();

	$( window ).resize(function(){
		screenWidth = $(window).width();
		selectStyler();
	});
});